/** 
 *------------------------------------------------------------------------------
 * @package       T3 Framework for Joomla!
 *------------------------------------------------------------------------------
 * @copyright     Copyright (C) 2004-2013 JoomlArt.com. All Rights Reserved.
 * @license       GNU General Public License version 2 or later; see LICENSE.txt
 * @authors       JoomlArt, JoomlaBamboo, (contribute to this project at github 
 *                & Google group to become co-author)
 * @Google group: https://groups.google.com/forum/#!forum/t3fw
 * @Link:         http://t3-framework.org 
 *------------------------------------------------------------------------------
 */


// VARIABLES & MIXINS
// ------------------
//@import "vars.less"; // Include Variables and Mixins


// EXTEND STYLE IN BASE
// --------------------
@import "../../import/default/megamenu";


// BASIC STYLE FOR MEGAMENU
// -------------------------
.plazart-megamenu {

  // THE MEGAMENU
  //--------------------------------------------

  // Global Menu Inner padding
  // -------------------------
  .mega-inner {
    padding: (@PlazartglobalPadding/2);
  }

  // Inner Padding for 1 column
  .span12 .mega-inner {
  }


  // Menu Grids
  // ----------
  .row-fluid {
  }

  .row-fluid + .row-fluid  {
    border-top: 1px solid @PlazartborderColor;
  }

  .row-fluid [class*="span"] {
  }


  // The Dropdown
  // ------------
  .mega-dropdown-menu {
  }


  // The Group
  // ---------
  .mega-group {
  }

  // Group Title
  .mega-nav .mega-group > .mega-group-title,
  .dropdown-menu .mega-nav .mega-group > .mega-group-title,
  .dropdown-menu .active .mega-nav .mega-group > .mega-group-title {
    background: inherit;
    color: @grayDarker;
    font-size: @PlazartbiggerFontSize;
    font-weight: bold;
    margin-bottom: 5px;

    &:hover, &:active, &:focus {
      background: inherit;
      color: @grayDarker;
    }
  }

  // Group Content
  .mega-group-ct {
  }

  .mega-group-ct > .row-fluid > [class*="span"] > .mega-inner {
  }


  // Nav in Megamenu
  // ---------------
  .mega-col-nav {
  }

  // Inner padding
  .mega-col-nav .mega-inner {
  }

  // Inner padding for nav in 1 column
  .span12.mega-col-nav .mega-inner {
  }

  // The Nav
  .mega-nav,
  .dropdown-menu .mega-nav {
  }

  .mega-nav > li,
  .dropdown-menu .mega-nav > li {
  }

  .mega-nav > li a,
  .dropdown-menu .mega-nav > li a {
    padding: 5px ( @PlazartglobalPadding / 2 );
  }

  // Nav in Group
  .mega-group > .mega-nav,
  .dropdown-menu .mega-group > .mega-nav {
  }

  .mega-group > .mega-nav > li,
  .dropdown-menu .mega-group > .mega-nav > li {
  }

  .mega-group .mega-nav > li a,
  .dropdown-menu .mega-group .mega-nav > li a {
  }

  // The caret
  .mega-nav .dropdown-submenu > a::after {
    margin-right: 0;
  }


  // Modules in Megamenu
  // -------------------
  .mega-col-module {
  }

  // Inner padding
  .mega-col-module .mega-inner {
  }

  // Inner padding for module in 1 column
  .span12.mega-col-nav .mega-inner {
  }

  // The module
  .plazart-module {
  }

  // Module Title
  .plazart-module .module-title {
    font-size: @PlazartbiggerFontSize;
    color: @grayDarker;
    display: block;
    line-height: @baseLineHeight;
    margin-bottom: 5px;
  }

  // Module Content
  .plazart-module .module-ct {
  }

  // List in Module
  // Reset List Style in Module
  .plazart-module ul,
  .plazart-module .nav {
    margin: 0 0 0 15px;
  }

  .plazart-module ul li,
  .plazart-module .nav li {
    list-style: disc;
    display: list-item;
    float: none;
    margin: 0;
    padding: 0;
    border: 0;
  }

  .plazart-module ul li a,
  .plazart-module .nav li a {
    display: inline;
    padding: 0;
    margin: 0;
    border: 0;
    font-size: 100%;
    background: none;
    font: inherit;
    white-space: normal;
    // Link states
    &:hover, &:focus, &:active {
      background: none;
      color: inherit;
      font: inherit;
    }
  }
  
  // Nav in Module
  .dropdown-menu .plazart-module .nav {
  }

  .dropdown-menu .plazart-module .nav li {
  }

  .dropdown-menu .plazart-module .plazart-module li a {
    // Link states
    &:hover, &:focus, &:active {
    }
  }
  

  // End
}
