/**
 *------------------------------------------------------------------------------
 * @package       Plazart Framework for Joomla!
 *------------------------------------------------------------------------------
 * @copyright     Copyright (C) 2004-2013 JoomlArt.com. All Rights Reserved.
 * @license       GNU General Public License version 2 or later; see LICENSE.txt
 * @authors       JoomlArt, JoomlaBamboo, (contribute to this project at github
 *                & Google group to become co-author)
 * @Google group: https://groups.google.com/forum/#!forum/plazartfw
 * @Link:         http://plazart-framework.org
 *------------------------------------------------------------------------------
 */
@import "../../../base/megamenu";
@import "../../../bootstrap/mixins";
// Plazart Base variables
@import "variables";

// BASIC STYLE FOR MEGAMENU
// -------------------------
.plazart-megamenu {

  // THE MEGAMENU
  //--------------------------------------------

  .navbar-nav {
    a {
      color: @gray;
      .fa {
        margin-right: 3px;
      }
      &:hover {
        background: inherit;
        color: inherit;
      }
    }
    .active > a {
      background: transparent;
      .box-shadow(0 0 0 #ffffff);
    }
  }

  // Global Menu Inner padding
  // -------------------------

  .mega-inner {
    padding: (@PlazartglobalPadding/2);
  }

  .mega-dropdown-inner {
    .row {
      margin-left: 0;
      margin-right: 0;
    }
  }

  .mega-dropdown-inner .last-col {
    margin-left: 0 !important;
  }

  .mega-dropdown-inner .last-col .mega-inner,
  .mega-dropdown-inner .row-fluid > div:last-child .mega-inner {
    padding: @PlazartglobalPadding  @PlazartglobalPadding 0 (@PlazartglobalPadding/2);
  }

  .mega-dropdown-inner .row-fluid > .span12.mega-col-nav:last-child .mega-inner {
    padding: @PlazartglobalPadding;
  }

  .nav-child.mega-group-ct .mega-dropdown-inner .last-col .mega-inner,
  .nav-child.mega-group-ct .mega-dropdown-inner .mega-col-nav .mega-inner,
  .nav-child.mega-group-ct .row-fluid > div:last-child .mega-inner,
  .span12.mega-col-nav:last-child .nav-child.mega-group-ct .row-fluid .mega-inner {
    padding: 0;
  }

  // Inner Padding for 1 column
  .span12 .mega-inner {
  }

  // Menu Grids
  // ----------
  .row-fluid {
  }

  .row-fluid + .row-fluid {
    border-top: 1px solid @PlazartborderColor;
  }

  .row-fluid [class*="span"] {
  }

  // The Dropdown
  // ------------
  .mega-dropdown-menu {
  }

  // The Group
  // ---------
  .mega-group {
  }

  // Group Title
  .mega-nav .mega-group > .mega-group-title,
  .dropdown-menu .mega-nav .mega-group > .mega-group-title,
  .dropdown-menu .active .mega-nav .mega-group > .mega-group-title {
    background: inherit;
    border-bottom: solid 1px @grayLighter;
    color: @white;
    font-size: @PlazartsmallerFontSize;
    font-weight: 400;
    margin-bottom: (@PlazartglobalPadding/2);
    padding-bottom: (@PlazartglobalPadding/2);
    text-transform: uppercase;

    &:hover, &:active, &:focus {
      background: inherit;
      color: @white;
    }
  }

  // Group Content
  .mega-group-ct .mega-dropdown-inner .mega-col-nav .mega-inner {
    border-right: none;
  }

  .mega-group-ct > .row-fluid > [class*="span"] > .mega-inner,
  .span12.mega-col-nav .mega-group-ct .mega-inner,
  .mega-col-nav .mega-group-ct .mega-inner {
    padding: 0;
    border-right: none;
  }

  // Nav in Megamenu
  // ---------------
  .mega-col-nav {
    padding-left: 0;
    padding-right: 0;
  }
  .mega-col-module {
    padding-left: 0;
    padding-right: 0;
  }

  // Inner padding
  .mega-dropdown-inner .mega-col-nav .mega-inner {
    border-right: solid 1px @color2c2c2c;
  }

  .mega-dropdown-inner .mega-col-nav:last-child .mega-inner {
    border-right: none;
  }

  // Inner padding for nav in 1 column
  .span12.mega-col-nav .mega-inner {
    padding: @PlazartglobalPadding;
  }

  // The Nav
  .mega-nav,
  .dropdown-menu .mega-nav {
  }

  .mega-nav > li,
  .dropdown-menu .mega-nav > li {
  }

  .mega-nav > li a {
    padding: 5px ( @PlazartglobalPadding / 2 );
  }

  .dropdown-menu .mega-nav > li a {
    padding: 5px 0;
  }

  // Nav in Group
  .mega-group > .mega-nav,
  .dropdown-menu .mega-group > .mega-nav {
  }

  .mega-group > .mega-nav > li,
  .dropdown-menu .mega-group > .mega-nav > li {
  }

  .mega-group .mega-nav > li a,
  .dropdown-menu .mega-group .mega-nav > li a {
    padding-left: 0;
    padding-right: 0;
  }

  // The caret
  .mega-nav .dropdown-submenu > a::after {
    margin-right: 0;
  }

  // Modules in Megamenu
  // -------------------
  .mega-col-module {
  }

  // Inner padding
  .mega-col-module .mega-inner {
  }

  // Inner padding for module in 1 column
  .span12.mega-col-nav .mega-inner {
  }

  // The module
  .plazart-module {
  }

  // Module Title
  .plazart-module .module-title {
    display: block;
    line-height: @baseLineHeight;
    border-bottom: 1px solid #333333;
    color: @white;
    font-size: 12px;
    font-weight: 400;
    margin-bottom: @PlazartglobalMargin/2;
    padding-bottom: @PlazartglobalPadding/2;
    text-transform: uppercase;
  }

  // Module Content
  .plazart-module .module-ct {
    color: #666;
    font-weight: 400;
  }

  // List in Module
  // Reset List Style in Module
  .plazart-module ul,
  .plazart-module .nav {
    margin: 0 0 0 15px;
  }

  .plazart-module ul li,
  .plazart-module .nav li {
    list-style: disc;
    display: list-item;
    float: none;
    margin: 0;
    padding: 0;
    border: 0;
  }

  .plazart-module ul li a,
  .plazart-module .nav li a {
    display: inline;
    padding: 0;
    margin: 0;
    border: 0;
    font-size: 100%;
    background: none;
    font: inherit;
    white-space: normal;
    // Link states
    &:hover, &:focus, &:active {
      background: none;
      color: inherit;
      font: inherit;
    }
  }

  .plazart-module .category-module {
    margin: 0;
    color: @gray;
    font-weight: 400;

    li {
      list-style: none;

      h4 {
        border-bottom: 1px solid #333;
        line-height: @baseLineHeight;
        margin-bottom: (@PlazartglobalMargin/2);
        padding-bottom: (@PlazartglobalPadding/2);
      }

      h4 a {
        color: @white;
        //Link States
        &:hover, &:focus, &:active {
          color: @white;
          text-decoration: none;
        }
      }

      p.mod-articles-category-readmore a {
        background: #01a3d4;
        color: @white;
        display: inline-block;
        font-size: 11px;
        opacity: 1;
        padding: 3px 14px;
        text-indent: 0;
        text-transform: uppercase;
//        .border-radius(2px);
        .box-shadow(~"inset 0 1px 0 rgba(255,255,255,.2), 0 1px 2px rgba(0,0,0,.1)");
        //Link States
        &:hover, &:focus, &:active {
          color: @white;
          text-decoration: none;
//          .buttonBackground(@btnPrimaryBackground, @btnPrimaryBackground, 0 -1px 0 rgba(0, 0, 0, 0.25));
        }
      }
    }

  }
  // Nav in Module
  .dropdown-menu .plazart-module .nav {
  }

  .dropdown-menu .plazart-module .nav li {
  }

  .dropdown-menu .plazart-module .plazart-module li a {
    // Link states
    &:hover, &:focus, &:active {
    }
  }

  // The caption
  // -----------
  .mega-caption {
    color: @grayLight;
    font-size: @PlazartsmallerFontSize;
    margin-top: 3px;
    font-weight: normal;
  }

  // End
}

.vertical-nav .plazart-megamenu {
  // Override animation for Plazart Verticle
  // ----------------------------------
  @media (min-width: @navbarCollapseDesktopWidth) {
    &.elastic {
      .level0 > .mega {
        .mega-dropdown-menu {
          transform: scale(0, 1);
          -webkit-transform: scale(0, 1);
          -ms-transform: scale(0, 1);
          -o-transform: scale(0, 1);
        }
        &.open > .mega-dropdown-menu {
          transform: scale(1, 1);
          -webkit-transform: scale(1, 1);
          -ms-transform: scale(1, 1);
          -o-transform: scale(1, 1);
        }
      }
    }

    &.slide {
      .mega > .mega-dropdown-menu {
        min-width: 0;
        & > div {
          min-width: 200px;
          margin-top: 0;
          margin-left: -500px;
        }
      }
      .mega.open > .mega-dropdown-menu > div {
        margin-left: 0;
      }
    }
  }
}
